const ACTIVE_QUICK_FILTERS = 'activeQuickFilters';
const PLAN_QUICK_FILTERS = 'planQuickFilters';

export const getActiveQuickFilters = (name: string) => {
	const { GH } = window;
	if (GH?.RapidBoard?.State) {
		if (name === 'rapidboard-board') {
			return GH.RapidBoard.State.getBoardSettings()[ACTIVE_QUICK_FILTERS];
		}
		if (name === 'rapidboard-backlog') {
			return GH.RapidBoard.State.getBoardSettings()[PLAN_QUICK_FILTERS];
		}
	}
	return '';
};

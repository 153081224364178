import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { getSpaStateLastUserLocation } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import type { Query } from '@atlassian/react-resource-router';
import {
	classicBacklog,
	classicBoard,
	classicReport,
} from '../../utils/performance-analytics/index.tsx';

const LOCATION = 'spa.rapidboard';

// PREFETCH ONLY FOR ISSUE VIEW AND PROJECTS-DIRECTORY
// TO-DO: Add it in constant file
const PREFETCH_USER_LOCATIONS = new Set([
	`${'directories'}:${'projects-directory'}`,
	`${'issue'}:${'issue'}`,
]);

// check if the apps loaded on last page contain any app on which prefetching was done.
const isPrefetchRoute = () => {
	const lastUserLocation = getSpaStateLastUserLocation();
	// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
	return PREFETCH_USER_LOCATIONS.has(lastUserLocation);
};

export const getApdexAttributes = (query: Query) => {
	const { GH } = window;
	try {
		const { modal, selectedIssue, view, quickFilter = '', assignee = '' } = query;
		const defaultAttributes = {
			isDetailView:
				(modal === 'detail' || view === 'detail' || view === 'planning') && selectedIssue !== '',
			isQuickFilterView: quickFilter !== '' || assignee !== '',
			isPrefetchRoute: isPrefetchRoute(),
		};

		return GH && GH.RapidBoard && GH.RapidBoard.State
			? {
					...defaultAttributes,
					...GH.RapidBoard.State.getApdexAttributes(),
				}
			: defaultAttributes;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		log.safeWarnWithoutCustomerData(LOCATION, 'Error getting rapidboard apdex attributes', err);
		return {};
	}
};

export const getBM3Metric = (routeName: string) => {
	switch (routeName) {
		case 'rapidboard-backlog':
		case 'rapidboard-user-backlog':
			return classicBacklog;
		case 'rapidboard-board':
		case 'rapidboard-user-board':
			return classicBoard;
		case 'rapidboard-report':
		case 'rapidboard-user-report':
			return classicReport;
		default:
			return undefined;
	}
};

import React from 'react';
import Button from '@atlaskit/button/new';
import ArrowLeftIcon from '@atlaskit/icon/core/migration/arrow-left';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type BackToBoardProps = {
	onClick: () => void;
};

export const BackToBoard = ({ onClick }: BackToBoardProps) => {
	const { formatMessage } = useIntl();

	return (
		<Button onClick={onClick} appearance="subtle">
			<Inline xcss={buttonTextStyles} alignBlock="center" as="span">
				<ArrowLeftIcon LEGACY_size="small" label="" />
				{formatMessage(messages.backToBoard)}
			</Inline>
		</Button>
	);
};

const buttonTextStyles = xcss({
	color: 'color.text.subtle',
	fontWeight: token('font.weight.regular'),
});

import { unmountComponentAtNode } from 'react-dom';
import { BENTO_ROOT } from '../../../constants.tsx';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		require?: any;
	}
}
export const cleanupResources = (resources: string[]): void => {
	if (resources && resources.length > 0) {
		resources.forEach((resource) => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (window.GH?.spa) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				delete window.GH.spa[resource];
			}
		});
	}
};

export const cleanupDOM =
	(doc: typeof document) =>
	(initialClasslist: string[], metaTags: HTMLElement[], isBentoOn: boolean | null) => {
		if (doc.body) {
			const documentBody: HTMLElement = doc.body;

			metaTags.forEach((metaTag) => {
				metaTag.parentNode && metaTag.parentNode.removeChild(metaTag);
			});

			if (isBentoOn === true) {
				const bentoRoot = doc.getElementById(BENTO_ROOT);
				bentoRoot && unmountComponentAtNode(bentoRoot);
			}

			const currentClassList = Array.from(documentBody.classList);
			currentClassList.forEach((className) => {
				documentBody.classList.remove(className);
			});

			initialClasslist
				.filter((className) => className !== 'deferred')
				.forEach((className) => {
					documentBody.classList.add(className);
				});
		}
	};

const WINDOW_EVENTS = [
	'confluence',
	'hashchange',
	'messageClose',
	'remove',
	'statechange',
	'blur.rapidboardSpa',
	'popstate.rapidboardSpa',
	'resize.rapidboardSpa',
	'unload.rapidboardSpa',
	'focus.rapidboardSpa',
];

export const cleanupGH = (win: typeof window, doc: typeof document) => () => {
	const { GH, AJS } = win;
	if (AJS && AJS.$) {
		AJS.$(win).off(WINDOW_EVENTS.join(' '));
		AJS.$(doc.body).off('click.rapidboardSpa');
		AJS.$('[draggable=true]').die('dragstart');
	}

	if (GH) {
		GH.spa = null;
		if (GH.Poller) {
			// removeAll just pauses the event listener. We need to manually call remove on individual pollers
			GH.Poller.removeAll && GH.Poller.removeAll();
			GH.Poller.removePoller && GH.Poller.removePoller('WorkPoller');
			GH.Poller.removePoller && GH.Poller.removePoller('linkedPagePoller');
		}
	}

	/**
	 * On cleanup we need to re-register the global issue create event handlers which will override the
	 * rapidboard specific ones
	 *
	 * NOTE: do not destructure this above to avoid confusing the bundler.
	 */
	win.require('jira/common/header').initialize();
};

export const hideGhControllers = () => {
	try {
		const { GH } = window;
		if (GH) {
			GH.WorkController && GH.WorkController.hide();
			GH.ReportController && GH.ReportController.hide();
			GH.PlanController && GH.PlanController.hide();
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty
	} catch (e: any) {}
};

export const cleanup = (
	initialClasslist: string[],
	metaTags: HTMLElement[],
	isBentoOn: boolean | null,
) => {
	try {
		cleanupDOM(document)(initialClasslist, metaTags, isBentoOn);
		cleanupGH(window, document)();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-empty
	} catch (e: any) {}
};

// Code is duplicated here because at this point that legacy code has not been loaded in the first place
export const REPORT_NAMES = {
	// clean up when removing reports-overview-page-migration FG
	burndownChart: 'sprintBurndown',
	burnupChart: 'burnup',
	sprintRetrospective: 'sprintReport',
	cumulativeFlowDiagram: 'cumulativeFlowDiagram',
	velocityChart: 'velocity',

	// for new URLs with reports-overview-page-migration FG turned on
	'burndown-chart': 'sprintBurndown',
	'burnup-chart': 'burnup',
	'sprint-retrospective': 'sprintReport',
	'cumulative-flow-diagram': 'cumulativeFlowDiagram',
	velocity: 'velocity',

	unknown: 'unknown',
} as const;

export const REASON_BOARD_CRITICAL_DATA_FAILED = 'board-critical-data-failed-unknown-reason';
export const REASON_BOARD_CRITICAL_DATA_FAILED_WITH_STATUS_CODE =
	'board-critical-data-failed-with-status-code';

export const UNKNOWN_STATUSCODE = '[unknown]';

import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import messages from './messages.tsx';

export const ErrorFlag = (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description: messages.description,
	isAutoDismiss: false,
	messageId: 'classic-software.common.ui.error-flag.flag.error.error-flag.error',
	messageType: 'transactional',
});
